<template>
  <b-field label="Billing entity (Staff only)">
    <b-select
      v-model="selected"
      :loading="processing"
      :disabled="processing"
      placeholder="Select a billing entity"
      expanded
      @input="updateEntity"
    >
      <option
        v-for="{ label, value } in entities"
        :key="value"
        :value="value"
        >{{ label }}</option
      >
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: "BillingEntityField",
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      selected: "",
      entities: [
        {
          label: "Fixed Dot Net, Inc",
          value: "fixed_dot_net_inc"
        },
        {
          label: "Fixed Group Ltd",
          value: "fixed_group_ltd"
        }
      ]
    };
  },
  computed: {
    userBillingEntity() {
      return this.$store.getters["user/billingEntity"](this.userId);
    }
  },
  watch: {
    userBillingEntity() {
      this.selectEntity();
    }
  },
  created() {
    this.selectEntity();
  },
  methods: {
    selectEntity() {
      this.selected = this.$_.clone(this.userBillingEntity);
    },
    updateEntity() {
      if (this.selected === this.userBillingEntity) return;
      this.processing = true;
      this.$store
        .dispatch("user/updateProfile", {
          userId: this.userId,
          payload: {
            billingEntity: this.selected
          }
        })
        .then(() => {
          this.$toast.open({
            message: "Billing entity updated",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(error => {
          this.selectEntity();
          this.$toast.open({
            message: error.message,
            type: "is-danger",
            position: "is-bottom",
            queue: false
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
